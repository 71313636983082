<template>
  <div id="app">
    <!-- <h3></h3> -->
    <FaqAdd
      v-if="!id && !isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :faq-details="faqDetails"
      @on-Submit="createFaq"
    />

    <!-- <h3>Edit Faq form</h3> -->
    <FaqAdd
      v-if="id && isLoaded"
      ref="simpleRules"
      :key="$route.fullPath"
      :is-loaded="isLoaded"
      :faq-details="faqDetails"
      @on-Submit="updateFaq"
    />
  </div>
</template>

<script>
import faqService from "@/services/faq/faq.service";
import { resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import checkLoginRole from '@/helpers/checkLoginRole'
import FaqAdd from "./FaqAdd.vue";
// import languageService from '../../../services/language/language.service';

export default {
  name: "FaqAddEdit",
  components: { FaqAdd },
  data() {
    return {
      isLoaded: false,
      faqDetails: {},
      id: this.$route.params.id ? this.$route.params.id : "",
      languageId: ""
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.getFaqById(this.$route.params.id);
      //   await this.getLanguageById(this.languageId)
    }
  },
  methods: {
    async createFaq(faqForm) {
      const valid = await this.$refs.simpleRules.validationForm();
      if (valid) {
        this.$refs.simpleRules.loading = true;
        faqService
          .addFaq(faqForm)
          .then(res => {
            this.$refs.simpleRules.loading = false;
            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push("/faq/list");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.simpleRules.loading = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    async getFaqById(id) {
      await faqService
        .getFaqById(id)
        .then(res => {
          this.isLoaded = true;
          if (resConditionCheck(res.status) && res.data.data) {
            // eslint-disable-next-line prefer-destructuring
            this.faqDetails = {
              category_id: res.data.data.categoryId,
              faq: res.data.data.faqLanguage
            };
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        });
    },

    async updateFaq(faqForm) {
      const valid = await this.$refs.simpleRules.validationForm();
      if (valid) {
        this.$refs.simpleRules.loading = true;
        faqService
          .updateFaq(this.id, faqForm)
          .then(res => {
            this.$refs.simpleRules.loading = false;
            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push("/faq/list");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.simpleRules.loading = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>
