var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.faqForm)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Catogery","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Catogery","label-for":"mc-select-catogery","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"category",attrs:{"placeholder":"Select Categories","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.faqCategories,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.faqForm.category_id),callback:function ($$v) {_vm.$set(_vm.faqForm, "category_id", $$v)},expression:"faqForm.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Languages","label-for":"mc-select-language","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"language",attrs:{"placeholder":"Select Languages","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.selectedLanguages,"reduce":function (val) { return val.value; },"clearable":false,"disabled":true},model:{value:(_vm.selectedLanguages),callback:function ($$v) {_vm.selectedLanguages=$$v},expression:"selectedLanguages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_vm._l((_vm.faqForm.faqs),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id,"label":item.id,"col":""}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":("Question for " + (item.languageName)),"label-for":"mc-question"}},[_c('validation-provider',{attrs:{"name":((item.languageName) + " Question"),"rules":"required|max:250|pattern"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b-form-textarea',{attrs:{"placeholder":("Question in " + (item.languageName)),"rows":"3"},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":("Answer for " + (item.languageName)),"label-for":"mc-answer"}},[_c('validation-provider',{attrs:{"name":((item.languageName) + " Answer"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}})],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary","type":"submit"}},[_vm._v(" Submit "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{
            name:
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin || _vm.checkAccess.view
                ? 'faq-list'
                : 'dashboard'
          }}},[_vm._v(" Cancel ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }