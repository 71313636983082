<template>
  <b-card-code>
    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', faqForm)">
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Catogery"
              rules="required"
            >
              <b-form-group
                label="Select Catogery"
                label-for="mc-select-catogery"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="faqForm.category_id"
                  class="category"
                  placeholder="Select Categories"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="faqCategories"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="Select Languages"
                label-for="mc-select-language"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="selectedLanguages"
                  class="language"
                  placeholder="Select Languages"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="selectedLanguages"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- alert -->

        <b-row
          v-for="(item, index) in faqForm.faqs"
          :id="item.id"
          :key="index"
          ref="row"
          :label="item.id"
          col
        >
          <b-col md="12">
            <b-form-group
              :label="`Question for ${item.languageName}`"
              label-for="mc-question"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${item.languageName} Question`"
                rules="required|max:250|pattern"
              >
                <div>
                  <b-form-textarea
                    v-model="item.question"
                    :placeholder="`Question in ${item.languageName}`"
                    rows="3"
                  />
                </div>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              :label="`Answer for ${item.languageName}`"
              label-for="mc-answer"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${item.languageName} Answer`"
                rules="required"
              >
                <template>
                  <quill-editor v-model="item.answer" :options="snowOption" />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button :disabled="loading" variant="primary" type="submit">
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{
              name:
                checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                  ? 'faq-list'
                  : 'dashboard'
            }"
          >
            Cancel
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import {
  roleOptions,
  genderOptions,
  RoleEnum,
  resConditionCheck
} from "@/helpers/constant";
import checkLoginRole from "@/helpers/checkLoginRole";
import vSelect from "vue-select";
import languageService from "@/services/language/language.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightFade } from "@core/directives/animations";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import faqService from "@/services/faq/faq.service";

// import accessRightCheck from '@/helpers/accessRightCheck'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    quillEditor,
    BFormTextarea
  },
  directives: {
    "height-fade": heightFade
  },

  props: {
    faqDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      faqForm: {
        category_id: "",
        faqs: []
      },
      staticLanguageObject: {
        language_id: "",
        question: "",
        answer: "",
        languageName: "",
        id: ""
      },
      show: true,

      selected: [],
      selectedCategory: "",
      required,
      items: this.faqDetails.faq ? this.faqDetails.faq : [],
      nextToDoId: 2,
      faqCategories: [],
      RoleEnum,
      checkLoginRole,
      loading: false,
      roleOptions,
      checkAccess: {},
      genderOptions,
      languages: [],
      toolbarOptions: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },

      editedArray: [],
      isEdit: false,
      rowData: this.faqDetails.faq ? this.faqDetails.faq : [],
      selectedLanguages: []
    };
  },

  async mounted() {
    await this.getLanguageDropdown();
    this.getCategoryListing();
  },

  methods: {
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            id: e.id
          }));
          if (this.$route.name === "faq-add") {
            for (let i = 0; i < res.data.data.length; i += 1) {
              this.faqForm.faqs.push({
                ...this.staticLanguageObject,
                language_id: res.data.data[i].id,
                languageName: res.data.data[i].name
              });
            }
          } else if (this.$route.name === "faq-edit") {
            this.faqForm.category_id = this.faqDetails.category_id;
            this.faqForm.id = this.faqDetails.faq[0].faqId;

            for (let i = 0; i < res.data.data.length; i += 1) {
              this.faqForm.faqs.push({
                ...this.staticLanguageObject,
                language_id: res.data.data[i].id,
                languageName: res.data.data[i].name,
                id: this.faqDetails.faq[i] && this.faqDetails.faq[i].id ? this.faqDetails.faq[i].id : ''
              });
              
              this.faqForm.faqs[i].question = this.faqDetails.faq[i] && this.faqDetails.faq[i].question ? this.faqDetails.faq[i].question : '';
              this.faqForm.faqs[i].answer = this.faqDetails.faq[i] && this.faqDetails.faq[i].answer ? this.faqDetails.faq[i].answer : '';
            }
          }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: { title: errorData, icon: "X-CircleIcon", variant: "danger" }
        });
      }
    },

    getCategoryListing() {
      faqService
        .getCategoryListing()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.faqCategories = res.data.data;
            this.faqCategories = this.faqCategories.map(item => ({
              label: item.name,
              value: item.id
            }));
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          if (error.response.status === 404) {
            this.faqCategories = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
</style>
